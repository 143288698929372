.ql-toolbar.ql-snow {
    border: 0;
}

.ql-container.ql-snow {
    border: 0;
}

.ql-snow .ql-stroke {
    stroke: #fff;
}

.ql-snow .ql-picker {
    color: #fff;
}

.ql-snow .ql-picker-options .ql-picker-item {
    color: #222;
}

.ql-snow .ql-picker-options .ql-picker-item .ql-stroke {
    stroke: #222;
}